var _navigator$language$m;
import { createSlice } from "@reduxjs/toolkit";
import en from "../Assets/lang/en.json";
import pl from "../Assets/lang/pl.json";
var language = /^(pl-PL|en-EN)$/.test(navigator.language) ? (_navigator$language$m = navigator.language.match(/^[a-z]+/)) === null || _navigator$language$m === void 0 ? void 0 : _navigator$language$m[0] : "en";
var initialState = { lang: language === "pl" ? pl : en, chosen: language === "pl" ? "pl" : "en" };
export var languageSlice = createSlice({ name: "language", initialState: initialState, reducers: { setLanguage: function setLanguage(state, action) { switch (action.payload) {
            case "en":
                state.chosen = "en";
                state.lang = en;
                break;
            case "pl":
                state.chosen = "pl";
                state.lang = pl;
                break;
            default:
                state.chosen = "en";
                state.lang = en;
                break;
        } } } });
var setLanguage = languageSlice.actions.setLanguage;
export { setLanguage };
export default languageSlice.reducer;
