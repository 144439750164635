import AuthenticationService from "./AuthenticationService";
export var RerouteService = function RerouteService(redirect) { var userObject = AuthenticationService.getUserInfo(); if (!userObject)
    return window.location.href = "/login"; if (userObject.role === "USER") {
    window.location.href = "/User/".concat(redirect ? redirect : process.env.REACT_APP_DEFAULT_PAGE);
}
else if (userObject.role === "PM") {
    window.location.href = "/PM/".concat(redirect ? redirect : process.env.REACT_APP_DEFAULT_PAGE);
}
else if (userObject.role === "HR") {
    window.location.href = "/HR/".concat(redirect ? redirect : process.env.REACT_APP_DEFAULT_PAGE);
}
else if (userObject.role === "ADMIN") {
    window.location.href = "/Admin/teams";
} };
