import AuthenticationService from "./AuthenticationService";
export var isAdmin = function isAdmin() { try {
    if (!AuthenticationService.getUserInfo())
        return false;
    return AuthenticationService.getUserInfo().role === "ADMIN";
}
catch (_unused) {
    return false;
} };
export var isHR = function isHR() { try {
    if (!AuthenticationService.getUserInfo())
        return false;
    return AuthenticationService.getUserInfo().role === "HR";
}
catch (_unused2) {
    return false;
} };
export var isPM = function isPM() { try {
    if (!AuthenticationService.getUserInfo())
        return false;
    return AuthenticationService.getUserInfo().role === "PM";
}
catch (_unused3) {
    return false;
} };
export var isUser = function isUser() { try {
    if (!AuthenticationService.getUserInfo())
        return false;
    return AuthenticationService.getUserInfo().role === "USER";
}
catch (_unused4) {
    return false;
} };
export default { isAdmin: isAdmin, isHR: isHR, isPM: isPM, isUser: isUser };
