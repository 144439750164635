import { validateEmail } from "./ValidateEmail";
import { ValidateText } from "./ValidateText";
export var TeamsListValidation = function TeamsListValidation(teams, snackbar, lang) { var valid = true; if (!teams || teams.length === 0)
    return false; teams.forEach(function (team) { if (!team.teamName || !ValidateText(team.teamName, 25)) {
    snackbar(lang.teamNameError, "error");
    valid = false;
} if (!team.pmEmail) {
    snackbar(lang.pmEmailError, "error");
    valid = false;
} if (!team.users || team.users.length === 0) {
    snackbar(lang.usersError, "error");
    valid = false;
} team.users.forEach(function (user) { if (!validateEmail(user.email)) {
    snackbar(lang.invalidEmails, "error");
    valid = false;
}
else if (!ValidateText(user.roleInProject, 25)) {
    snackbar(lang.invalidData, "error");
    valid = false;
} }); }); return valid; };
