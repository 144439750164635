import { ValidateText } from "../../../../Services/Validation/ValidateText";
export var validateNewQuestionSet = function validateNewQuestionSet(newQuestionSet, snackbar, lang, errMsg) { if (!newQuestionSet || typeof newQuestionSet !== "object") {
    snackbar(errMsg.invalidObject, "error");
    return false;
} var feedback360QuestionSet = newQuestionSet.feedback360QuestionSet, questions = newQuestionSet.questions; if (!feedback360QuestionSet || typeof feedback360QuestionSet !== "object") {
    snackbar(errMsg.invalidObject, "error");
    return false;
} var selfAssessmentQuestions = questions.filter(function (question) { return question.selfAssessment; }); if (feedback360QuestionSet.enableSelfAssessment && selfAssessmentQuestions.length === 0) {
    snackbar(lang.errors.selfAssessmentQuestionRequired, "error");
    return false;
} if (!feedback360QuestionSet.name || !ValidateText(feedback360QuestionSet.name, 100)) {
    snackbar(lang.errors.invalidQuestionSetName, "error");
    return false;
} if (!feedback360QuestionSet.description || !ValidateText(feedback360QuestionSet.description, 250)) {
    snackbar(lang.errors.invalidQuestionSetDescription, "error");
    return false;
} if (!questions || questions.length === 0) {
    snackbar(lang.errors.emptyQuestionSet, "error");
    return false;
} for (var i = 0; i < questions.length; i++) {
    var tempQuestion = questions[i];
    if (!tempQuestion.question || !ValidateText(tempQuestion.question, 500)) {
        snackbar(lang.errors.missingOrInvalidParmas, "error");
        return false;
    }
    if (!tempQuestion.type) {
        snackbar(lang.errors.missingOrInvalidParmas, "error");
        return false;
    }
    if (tempQuestion.type !== "OPEN_TEXT" && !tempQuestion.labels) {
        snackbar(lang.errors.missingOrInvalidParmas, "error");
        return false;
    }
    if (tempQuestion.type !== "OPEN_TEXT" && tempQuestion.type !== "ONE_CHOICE") {
        var keys = Object.keys(questions[i].labels);
        if (!keys || keys.length < 1 || !Array.isArray(keys)) {
            snackbar(lang.errors.missingOrInvalidParmas, "error");
            return false;
        }
        for (var j = 0; j < keys.length; j++) {
            var key = keys[j];
            if (!tempQuestion.labels[key] || tempQuestion.labels[key] === "" || !ValidateText(tempQuestion.labels[key], 200)) {
                snackbar(lang.errors.missingOrInvalidParmas, "error");
                return false;
            }
        }
    }
    else if (tempQuestion.type === "ONE_CHOICE") {
        var answers = tempQuestion.labels.answersArray;
        if (!answers || answers.length < 2 || !Array.isArray(answers)) {
            snackbar(lang.errors.missingOrInvalidParmas, "error");
            return false;
        }
        for (var _j = 0; _j < answers.length; _j++) {
            if (!answers[_j] || answers[_j] === "" || !ValidateText(answers[_j], 300)) {
                snackbar(lang.errors.missingOrInvalidParmas, "error");
                return false;
            }
        }
    }
} return true; };
export var validateEditQuestionSet = function validateEditQuestionSet(newQuestionSet, snackbar, lang, errMsg) { var questions = newQuestionSet; if (!questions || questions.length === 0) {
    snackbar(lang.errors.emptyQuestionSet, "error");
    return false;
} for (var i = 0; i < questions.length; i++) {
    var tempQuestion = questions[i];
    if (!tempQuestion.question || !ValidateText(tempQuestion.question, 500)) {
        snackbar(lang.errors.missingOrInvalidParmas, "error");
        return false;
    }
    if (!tempQuestion.type) {
        snackbar(lang.errors.missingOrInvalidParmas, "error");
        return false;
    }
    if (tempQuestion.type !== "OPEN_TEXT" && !tempQuestion.labels) {
        snackbar(lang.errors.missingOrInvalidParmas, "error");
        return false;
    }
    if (tempQuestion.type !== "OPEN_TEXT" && tempQuestion.type !== "ONE_CHOICE") {
        var keys = Object.keys(questions[i].labels);
        if (!keys || keys.length < 1 || !Array.isArray(keys)) {
            snackbar(lang.errors.missingOrInvalidParmas, "error");
            return false;
        }
        for (var j = 0; j < keys.length; j++) {
            var key = keys[j];
            if (!tempQuestion.labels[key] || tempQuestion.labels[key] === "" || !ValidateText(tempQuestion.labels[key], 200)) {
                snackbar(lang.errors.missingOrInvalidParmas, "error");
                return false;
            }
        }
    }
    else if (tempQuestion.type === "ONE_CHOICE") {
        var answers = tempQuestion.labels.answersArray;
        if (!answers || answers.length < 2 || !Array.isArray(answers)) {
            snackbar(lang.errors.missingOrInvalidParmas, "error");
            return false;
        }
        for (var _j2 = 0; _j2 < answers.length; _j2++) {
            if (!answers[_j2] || answers[_j2] === "" || !ValidateText(answers[_j2], 300)) {
                snackbar(lang.errors.missingOrInvalidParmas, "error");
                return false;
            }
        }
    }
} return true; };
